<!-- 订单管理-充电订单 -->

<template>
	<div class="charging-order main-cnt">
		<div class="content">
			<common-table ref="roleTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
				:extraParame="{req_type:'1'}" :needExport="needExport" @export="exportExcel"
				:apiName="Order.getChargingOrderLists" :filters="filters" :columns="tableColumns"
				@onDetailBtn="onDetailBtn" @calculateTotal="calculateTotal" @changeStation="changeStation">
				<!-- 合计 -->
				<template #amount>
					<div class="flex amount">
						<div>
							<span class="amount-title f-w-bold">合计</span>
							<span class="order-total">{{ totalAmount.orderTotal }}</span>
							<span style="margin-left: 30px;" class="title-num-green">{{ totalAmount.eleTotal }}</span>
							<span style="margin-left: 30px;" class="title-num-green">{{ totalAmount.serTotal }}</span>
						</div>
						<!-- <div class="" style="margin-right:280px;color: #1AC994;">{{ totalAmount.preferentialTotal }}</div> -->
						<div class="total-paid">
							<span style="margin-right: 50px;">{{ totalAmount.preferentialTotal }}</span>
							<span>{{ totalAmount.totalPaid }}</span>
						</div>
					</div>
					<p style="color: red;font-size: 12px;">特别说明：充电中的订单充电结束时间、充电时长、电量为实时采样数据</p>
				</template>
			</common-table>
		</div>
		<!-- 订单详情 -->
		<orderDetail ref="detailsDialog" :orderDetails="orderDetails" @onClickUser="onClickUser"></orderDetail>

		<!-- 会员订单详情 -->
		<memberOrderDetails ref="memberDialog" @onOrderDetailBtn="onOrderDetailBtn"></memberOrderDetails>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		computed,
		watch,
	} from "vue";
	import {
		Order,
		PowerStations,
	} from "@/plugins/api.js";
	import {
		ElMessage
	} from "element-plus";
	import {
		useStore
	} from "vuex";
	import dayjs from "dayjs";
	import memberOrderDetails from "../components/memberOrderDetails.vue";
	import orderDetail from "../components/orderDetail.vue";
	import getBaseUrl from "@/plugins/baseUrl.js";
	const baseUrl = getBaseUrl() ? getBaseUrl() : "";
	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);

	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})
	
	//判断是否有导出权限
	const needExport = computed(() =>{
		return authData.value.indexOf('n_1bzkYtiZuNQczQAhC47XHeMsGBRV') != -1
	})

	const exportExcel = (e) => {
		let reqData = {
			start_time: filters.value[0].value ? filters.value[0].value[0] : '',
			end_time: filters.value[0].value ? filters.value[0].value[1] : '',
			status: filters.value[1].value,
			s_id: filters.value[2].value,
			e_id: filters.value[3].value,
			req_type: 1,
			keywords: filters.value[5].value,
			payway: filters.value[4].value,
		}
		const reqDataUrl =
			`?start_time=${reqData.start_time}&end_time=${reqData.end_time}&status=${reqData.status}&s_id=${reqData.s_id}&e_id=${reqData.e_id}&req_type=${reqData.req_type}&keywords=${reqData.keywords}&payway=${reqData.payway}`
		location.href = `${baseUrl}/charge/order/export${reqDataUrl}`
	}

	/** 表格对象 */
	const roleTable = ref(null);
	// 合计
	const totalAmount = ref({
		orderTotal: 0, // 订单合计
		preferentialTotal: 0, // 优惠合计
		totalPaid: 0, // 已付合计
		eleTotal: 0,
		serTotal: 0
	})
	const detailsDialog = ref(null); // 详情对象
	const orderDetails = ref({}); // 订单详情数据
	const memberDialog = ref(null); // 会员订单详情
	/** 筛选条件列表 */
	const filters = ref([{
			filterType: "date",
			name: "start_time",
			name2: "end_time",
			value: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
			// value: [],
			placeholder: "请选择日期范围",
			type: "daterange",
		},
		{
			filterType: "select",
			name: "status",
			value: "",
			placeholder: "请选择充电状态",
			options: [{
				id: '1',
				name: '充电中'
			}, {
				id: '4',
				name: '已完成'
			}, {
				id: '-1',
				name: '待启电'
			}],
			val: "id",
			lab: "name",
		},
		{
			filterType: "select",
			name: "s_id",
			value: "",
			placeholder: "请选择充电站",
			options: [],
			val: "s_id",
			lab: "s_name",
			action: "changeStation"
		},
		{
			filterType: "select",
			name: "e_id",
			value: "",
			placeholder: "请选择充电桩",
			options: [],
			val: "e_id",
			lab: "e_name",
		},
		{
			filterType: "select",
			name: "payway",
			value: "",
			placeholder: "请选择支付方式",
			options: [{
				id: 1,
				name: '微信支付'
			}, {
				id: 2,
				name: '余额支付'
			}],
			val: "id",
			lab: "name",
		},
		{
			name: "keywords",
			filterType: "search",
			value: "",
			placeholder: "请输入订单编号、支付单号或手机号查询",
		},

	]);

	/** 表格配置数据 */
	const tableColumns = ref([{
			prop: "orw_no",
			label: "订单编号",
			color: "--text-color",
			showTooltip: true,
			minWidth: 150
		},
		{
			prop: "orw_total_money",
			label: "订单金额",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_elec_money",
			label: "电费",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_sevice_money",
			label: "服务费",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "or_status_title",
			label: "订单状态",
			type: "option",
			metaData: ["进行中", "待支付", "已支付"],
			colorData: ["var(--text-blue-color)", "var(--error-color)", "var(--theme-color)", ],
			showTooltip: true,
		},
		{
			prop: "orw_status_title",
			label: "充电状态",
			type: "option",
			metaData: ["充电中", "已完成"],
			colorData: ["var(--text-blue-color)", "var(--theme-color)", ],
			showTooltip: true,
		},
		{
			prop: "s_name",
			label: "充电站",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "e_name",
			label: "充电桩",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "c_name",
			label: "充电枪",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_start_time",
			label: "充电开始",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_end_time",
			label: "充电结束",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "charg_duration",
			label: "充电时长",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_total_power",
			label: "电量(度)",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "total_discount",
			label: "减免合计",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "payed_money",
			label: "已付金额",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "pay_way",
			label: "收款方式",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_system_check_time",
			label: "收款时间",
			color: "--text-color",
			showTooltip: true,
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 100,
			bottons: [{
				name: "详情",
				action: "onDetailBtn",
				token: "n_vqZS1xZb0dLpirxShcPZOCduRAnn",
			}, ],
		},
	]);
	/**
	 * 
	 * 获取充电站数据
	 * 
	 * */
	const getPowerStationLists = () => {
		PowerStations.getPowerCommonStationList().then((res) => {
			if (res.Code === 200) {
				filters.value[2].options = res.Data ? res.Data : [];

			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	const changeStation = (e) => {
		roleTable.value.tableLoad();
		filters.value[3].value = ''
		getEquipmentLists(e.value)
	}
	/**
	 * 
	 * 获取充电桩数据
	 * 
	 * */
	const getEquipmentLists = (id) => {
		PowerStations.getPowerCommonEquipmentList({
			s_id: id
		}).then((res) => {
			if (res.Code === 200) {
				filters.value[3].options = res.Data ? res.Data : [];
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 计算合计
	 * 
	 * */
	const calculateTotal = (datas) => {
		totalAmount.value = {
			orderTotal: 0,
			totalPaid: 0,
			preferentialTotal: 0,
			eleTotal: 0,
			serTotal: 0
		}
		if (datas.length > 0) {
			datas.forEach(item => {
				if (item.or_status_mark == '2' || item.or_status_mark == '1') {
					totalAmount.value.orderTotal += Number(item.orw_total_money);
					if (!isNaN(item.payed_money)) {
						totalAmount.value.totalPaid += Number(item.payed_money);
					}
					if (!isNaN(item.total_discount)) {
						totalAmount.value.preferentialTotal += Number(item.total_discount);
					}
					if (!isNaN(item.orw_sevice_money)) {
						totalAmount.value.serTotal += Number(item.orw_sevice_money);
					}
					if (!isNaN(item.orw_elec_money)) {
						totalAmount.value.eleTotal += Number(item.orw_elec_money);
					}
				}
			})
			totalAmount.value.eleTotal = totalAmount.value.eleTotal.toFixed(2);
			totalAmount.value.serTotal = totalAmount.value.serTotal.toFixed(2);
			totalAmount.value.orderTotal = totalAmount.value.orderTotal.toFixed(2);
			totalAmount.value.totalPaid = totalAmount.value.totalPaid.toFixed(2);
			totalAmount.value.preferentialTotal = totalAmount.value.preferentialTotal.toFixed(2);
		}
	}
	/**
	 * 
	 * 查看详情按钮
	 * 
	 * */
	const onDetailBtn = (row) => {
		Order.getChargingOrderInfo({
			orw_id: row.orw_id
		}).then((res) => {
			if (res.Code === 200) {
				detailsDialog.value.openDialog();
				orderDetails.value = res.Data;
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 订单详情-点击用户名称
	 * 
	 * */
	const onClickUser = (data) => {
		let req = {
			m_id: data.m_id,
			order_type: 1
		}
		memberDialog.value.openDialog(req);
	}
	/**
	 * 
	 * 用户订单详情-点击详情按钮
	 * 
	 * */
	const onOrderDetailBtn = (row) => {
		onDetailBtn(row);
	}

	onMounted(() => {
		getPowerStationLists();
		roleTable.value.tableLoad();
	});
</script>

<style lang="scss">
	.charging-order {
		font-family: "Source Han Sans CN";

		.el-row {
			border: none;
		}

		.search-input {
			width: 300px !important;
		}

		.content {
			padding: 20px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 20px 20px 30px 30px;
			}
		}

		.bg-color {
			background-color: #F4FDFA;
		}
	}

	.amount {
		margin-top: 10px;
		justify-content: space-between;
	}

	.amount-title {
		font-size: 18px;
	}

	.order-total {
		margin-left: 123px;
		color: #1AC994;
	}

	.total-paid {
		margin-right: 290px;
		color: #1AC994;
	}

	.order-left {
		margin-bottom: 20px;
		padding: 20px;
	}

	.charging-state {
		margin-bottom: 20px;
	}

	.charged {
		padding: 20px 30px;
	}

	.time-on {
		padding: 22px 20px;
	}

	.order-right {
		padding: 20px 15px !important;
	}

	.charging-img {
		width: 50px;
		height: 50px;
	}
</style>